
import React from 'react';
import { useAnnouncement } from '@/context/AnnouncementContext';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';

export const AnnouncementBanner = () => {
  const { announcement } = useAnnouncement();

  if (!announcement || !announcement.is_active) {
    return null;
  }

  return (
    <div className="w-full bg-red-600 flex items-center">
      {/* Announcement Heading with Triangle */}
      <div className="text-white py-1 px-4 font-bold flex items-center">
        Announcements
        <div className="w-0 h-0 border-t-[15px] border-t-transparent border-b-[15px] border-b-transparent border-l-[15px] border-l-white/20 ml-2"></div>
      </div>
      
      {/* Announcement Content with Animation */}
      <div className="flex-grow bg-red-700 overflow-hidden py-1">
        <div className="w-full overflow-hidden">
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: "-100%" }}
            transition={{
              duration: 15,
              repeat: Infinity,
              ease: "linear",
            }}
            className="whitespace-nowrap text-sm font-medium text-white"
          >
            {announcement.message}
          </motion.div>
        </div>
      </div>
    </div>
  );
};
