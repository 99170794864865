
import React, { ReactNode, memo } from 'react';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { AnnouncementBanner } from './AnnouncementBanner';

type LayoutProps = {
  children: ReactNode;
};

// Using memo to prevent unnecessary Layout re-renders
export const Layout = memo(function Layout({ children }: LayoutProps) {
  // Add error boundary for layout rendering
  try {
    return (
      <div className="flex flex-col min-h-screen">
        <div className="sticky top-0 z-50">
          <AnnouncementBanner />
          <Navbar />
        </div>
        <main className="flex-grow">
          {children}
        </main>
        <Footer />
      </div>
    );
  } catch (error) {
    console.error('Error rendering layout:', error);
    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow flex items-center justify-center">
          <div className="text-center p-8">
            <h1 className="text-2xl font-bold mb-4">Layout Error</h1>
            <p>There was a problem loading the page layout.</p>
            <button 
              onClick={() => window.location.reload()} 
              className="mt-4 px-4 py-2 bg-primary text-white rounded"
            >
              Reload Page
            </button>
          </div>
        </div>
      </div>
    );
  }
});
